import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "components/Common/Logo";
import LoginForm from "components/Login/LoginForm";
import Testimonial from "components/Login/Testimonial";
import { useAuth0 } from "@auth0/auth0-react";

// Define a type for the extended authorization parameters
type AuthorizationParams = {
  redirectUri: string;
  audience: string | undefined;
  scope: string;
  invitation?: string; // Add optional properties
  organization?: string;
  organization_name?: string;
};

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();
  const handleSubmit = () => {
    console.log('Login submitted');
    
    // Extract invitation parameters from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const invitation = urlParams.get('invitation');
    const organization = urlParams.get('organization');
    const organizationName = urlParams.get('organization_name');

    // Prepare the login parameters
    const loginParams = {
      authorizationParams: {} as AuthorizationParams, // Use the new type
      redirectUri: window.location.origin + "/home",
      audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
      scope: "openid profile email",
    };

    // Include invitation parameters if they exist
    if (invitation && organization && organizationName) {
      loginParams.authorizationParams.invitation = invitation;
      loginParams.authorizationParams.organization = organization;
      loginParams.authorizationParams.organization_name = organizationName;
    }

    loginWithRedirect(loginParams);
  };

  const handleGoogleSignIn = () => {
    console.log("Google Sign In clicked");
    // Implement Google Sign In logic here
    // After successful sign-in, navigate to home
    navigate("/home");
  };

  const handleForgotPassword = () => {
    console.log("Forgot Password clicked");
    // Implement forgot password logic here
  };

  const handleSignUp = () => {
    console.log("Sign Up clicked");
    loginWithRedirect({
      authorizationParams: {
        redirectUri: window.location.origin + "/home",
        screen_hint: "signup", // This hints to Auth0 that you want the signup screen
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        scope: "openid profile email",
        responseType: "code", // Optional, depends on your flow setup
        prompt: "login", // Optional; forces the user to see the signup/login screen
      },
    });
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <main className="flex min-h-screen bg-white">
        <div className="flex flex-grow">
          <section className="flex flex-col justify-center items-start w-full max-w-screen-sm px-8 py-8 relative">
            <Logo />
            <LoginForm
              onSubmit={handleSubmit}
              onGoogleSignIn={handleGoogleSignIn}
              onForgotPassword={handleForgotPassword}
              onSignUp={handleSignUp}
            />
            <footer className="absolute bottom-8 left-8 text-sm text-slate-600">
              © DECAID AI Studio
            </footer>
          </section>
        </div>
        <Testimonial />
      </main>
    </div>
  );
};

export default LoginPage;
