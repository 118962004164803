import Header from 'components/Common/Header';
import React from 'react';

const DesignPage: React.FC = () => {
  return (
    <>
      <Header title="Design" />
      {/* Add your design page content here */}
    </>
  );
};

export default DesignPage;
