import React from 'react';
import Header from 'components/Common/Header';

const SupportPage: React.FC = () => {
  return (
    <>
      <Header title="Support" />
      {/* Add your support page content here */}
    </>
  );
};

export default SupportPage;
