import React, { useState } from 'react';
import Header from 'components/Common/Header';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserContext } from "contexts/UserContext";
import userAvatar from 'assets/images/userAvatar.png';

const SettingsPage: React.FC = () => {
  const { userData, setUserData } = useUserContext();
  const [formData, setFormData] = useState({
    uuid: userData?.uuid || '',
    first_name: userData?.first_name || '',
    last_name: userData?.last_name || '',
    email: userData?.email || '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    const token = await getAccessTokenSilently();
    const user_id = userData?.uuid;
    const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
    const response = await fetch(backend_api_url + `/users/update/${user_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });
    if (response.ok) {
      // Update userData in session storage
      const updatedUserData = { ...userData, ...formData };
      sessionStorage.setItem('userData', JSON.stringify(updatedUserData));
      // Update userData in context
      setUserData(updatedUserData);
    }
    setIsEditing(false);
  };

  return (
    <>
      <Header title="Settings" />
      <div className="p-6 bg-white shadow rounded-lg max-w-md mx-auto mt-8">
        {isEditing ? (
          <form onSubmit={handleFormSubmit}>
            <div className="flex items-center space-x-4">
              <img
                src={userAvatar}
                alt="User Avatar"
                className="w-16 h-16 rounded-full object-cover"
              />
              <div>
                <label className="block text-gray-700">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <label className="block text-gray-700 mt-4">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <label className="block text-gray-700 mt-4">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-6">
              <button
                type="submit"
                className="px-4 py-2 bg-purple-600 text-white rounded-lg shadow hover:bg-purple-700"
              >
                Save Changes
              </button>
            </div>
          </form>
        ) : (
          <>
            <div className="flex items-center space-x-4">
              <img
                src={userAvatar}
                alt="User Avatar"
                className="w-16 h-16 rounded-full object-cover"
              />
              <div>
                <h2 className="text-xl font-semibold text-gray-900">
                  {userData?.first_name} {userData?.last_name}
                </h2>
                <p className="text-gray-600">{userData?.email}</p>
              </div>
            </div>
            <div className="mt-6">
              <button
                onClick={() => setIsEditing(true)}
                className="px-4 py-2 bg-purple-600 text-white rounded-lg shadow hover:bg-purple-700"
              >
                Edit Profile
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SettingsPage;